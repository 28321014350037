.block__missions {
  display: flex;
  width: 100%;
}

.mission {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.div--clients {
  display: flex;
  gap: 15px;
  padding: 60px 20px 0px;
  margin: -20px 0px 10px -20px;
  max-width: calc(100% + 20px);
  overflow-x: auto;
  scroll-behavior: smooth;
}

.div--clients::-webkit-scrollbar {
  display: none;
}

.situation-tag {
  background-color: $color-light-light-blue;
  padding: 5px 8px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: -(40px);
  width: 150px;
}

.card--client {
  flex: 0 0 auto;
  padding: 20px;
  justify-content: space-between;
  height: 100%;

  border-radius: $border-radius-default;
  background-color: $color-light-light-blue;

  .card--client__div--dates {
    display: flex;
    gap: 5px;
    color: $color-black;
    font-weight: bold;
    margin-bottom: 10px;

    .date--end {
      white-space: nowrap;
    }
  }

  .card--client__div--client {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 60px;

    h3 {
      font-weight: bold;
      color: $color-dark-blue;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  .card--client__div--workplace {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.div--arrow {
  display: none;
}

@media screen and (min-width: 641px) {
  .div--arrow {
    display: flex;
    position: relative;
    top: 150px;
    left: -20px;
    width: calc(100% + 20px);
    justify-content: space-between;

    .arrow {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
      color: $color-light-blue;
      border: none;
      font-size: 14px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: scale(105%);
      }
    }
    .arrow--left {
      background-color: $color-white;
      margin-right: auto;
    }
    .arrow--right {
      background-color: $color-white;
      margin-left: auto;
    }
  }
}
