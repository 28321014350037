.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;

  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: $color-third;
    width: 100%;

    .navbar__brand {
      font-size: 1.25em;
      font-weight: bold;

      a {
        color: $color-primary;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .navbar__user {
      display: flex;
      flex-direction: row;
      padding: 10px 0;
      width: calc(100% + 30px);
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 641px) {
  .banner {
    padding: 0px 10px;
    .navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      .navbar__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        gap: 20px;
        margin: 0;
      }
    }
  }
}
