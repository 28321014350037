.toast {
  min-width: 250px;
  margin-left: 10px;
  background-color: $color-white;
  text-align: left;
  border-radius: $border-radius-default;
  padding: 16px;
  position: fixed;
  z-index: 99999;
  right: 10px;
  bottom: 10px;
  @include box-shadow;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 7.5s;
  animation: fadein 0.5s, fadeout 0.5s 7.5s;

  &.success {
    color: $color-green;
  }

  &.error {
    color: $color-red;
  }
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 10px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
