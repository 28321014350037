$base-font-size: 16px;

.form {
  width: 90%;
  margin: 0 auto;
  padding: 0 0.63em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__container {
  width: 100%;
  position: absolute;
  top: 100px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 99;
  text-align: center;
  padding-bottom: 15px;
}

.form__control {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bold;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1.25em;
}

.form__picture {
  width: 100%;
  .cross-button--picture {
    position: absolute;
    border: none;
    font-size: 18px;
    text-decoration: none;
    outline: none;
    background-color: transparent;
    visibility: hidden;

    right: 30%;

    &:hover {
      cursor: pointer;
    }

    &.reset-edit {
      padding-right: 5px !important;
    }

    &.show {
      visibility: visible;
    }
  }
}

.form__input {
  background-color: $color-input;
  border: 0.06em solid $color-light-light-blue;
  height: 2.5em;
  padding: 0.31em 0.63em;
  border-radius: 0.25em;
  font: inherit;
  font-weight: normal;
  font-size: 0.88em;
  outline: none;
  width: 100%;

  &.form__input--error {
    border: 0.06em solid red;
    outline: none !important;
  }

  &:focus {
    border-color: $color-secondary;
  }
}

.form__input--select-month {
  margin-right: 10px;
  width: 45%;
}

.form__input--select-year {
  width: 45%;
}

.form__input--search-serlian {
  background-color: $color-input;
  border: 1px solid $color-light-light-blue;
  margin-top: 10px;
  height: 40px;
  padding: 5px 10px;
  border-radius: 4px;
  font: inherit;
  font-weight: normal;
  font-size: 14px;
  outline: none;
  width: 100%;

  &.form-error {
    border: 1px solid red;
    outline: none !important;
  }

  &:focus {
    border-color: $color-secondary;
  }
}

.form__line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;
    align-items: center;
    border-radius: $border-radius-default;
  }
}

.form--mobile {
  width: 100%;
}

.form--modal {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  display: flex;
  visibility: hidden;
  transition: all 0.2s linear;
  z-index: 9999;
  margin-top: 0;

  &.toggle-add {
    visibility: visible;
    opacity: 1;
  }
}

.form--modal__container {
  width: 80%;
  max-height: 90%;
  background-color: $color-white;
  margin: auto;
  padding: 20px;
  border-radius: 2px;

  &.overflow-auto {
    overflow: auto;
  }
}

//other elements

.cross-button {
  position: absolute;
  border: none;
  font-size: 18px;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  top: 1%;
  right: 3%;
  height: 100%;
  visibility: hidden;

  &:hover {
    cursor: pointer;
  }

  &.reset-edit {
    right: 11px;
    padding-right: 5px !important;
  }

  &.show {
    visibility: visible;
  }
}

.cross--delete {
  border: none;
  background-color: transparent;
  color: $color-light-blue;

  &:hover {
    cursor: pointer;
  }
}

textarea {
  resize: vertical;
  height: 5em !important;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: $color-white;
  height: 1em;
  width: 1em;
  border: 0.06em solid $color-black;
  border-radius: 0.31em;
}
input[type="checkbox"]:after {
  content: "";
  position: relative;
  left: 40%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: 0.06em solid #fff;
  border-width: 0 0.13em 0.13em 0;
  transform: rotate(45deg);
  display: none;
}
input[type="checkbox"]:checked {
  background: $color-light-blue;
  border: none;
}
input[type="checkbox"]:checked:after {
  display: block;
}
input[type="checkbox"]:disabled {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
input[type="checkbox"]:disabled:after {
  border-color: #7b7b7b;
}

//Overriding autosuggest styles in search and input bars
.react-autosuggest__container {
  position: relative;
  flex: 1;

  .form-input {
    width: 100%;
  }

  &.react-autosuggest__container--open {
    .form-input,
    .input-serlian {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .react-autosuggest__suggestions-container {
    display: none;

    &.react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      width: 100%;
      border: 1px solid $color-secondary;
      border-top: none;
      background-color: #fff;
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
      overflow: auto;
      max-height: 11em;

      .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .react-autosuggest__suggestion {
          cursor: pointer;
          padding: 10px 20px;
        }

        .react-autosuggest__suggestion--highlighted {
          background-color: #ddd;
        }
      }
    }
  }
}

@media screen and (min-width: 40.06em) {
  .form {
    width: 50% !important;
    margin: auto;
  }

  .form-modal {
    .modal-add-container {
      width: 70% !important;
    }
  }
}
