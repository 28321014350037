/*FONTS*/

.serli {
  color: $color-primary;
}

h1 {
  color: $color-secondary;
  text-transform: uppercase;
  font-size: 28px;
  letter-spacing: 1px;
  // margin: 20px 0;
}

h2 {
  color: $color-secondary;
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 2px;
  // margin: 20px 0;
}

h3 {
  font-weight: bold;
  color: $color-black;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h4 {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.8px;
  color: $color-primary;
}

h5 {
}

p {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: $color-black;

  &:hover {
    color: $color-black;
  }
}

em {
  font-size: 0.88em;
  color: $color-red;
  float: right;
}

.baseline {
  font-size: 12;
  width: 90%;
  text-align: center;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.advice {
  font-size: 14px;
  margin-bottom: 10px;
  font-style: italic;
  display: flex;
  flex-direction: column;
}

.hidden-i {
  width: 151px;
  height: 234px;
  margin: 10px;
}

.defaultPhrase {
  padding-left: 20px;
  color: $color-black;
}

@media screen and (min-width: 641px) {
  /*FONTS*/
  h1 {
    font-size: 30px;
  }
  h4 {
    font-size: 12px;
  }
}
