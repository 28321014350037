.is-pending {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  svg {
    position: fixed;
    top: 50%;
    font-size: 100px;
    color: $color-secondary;
    animation: rotateAnim 1s linear infinite;
  }
}

@keyframes rotateAnim {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
