/*LISTS*/
.list__line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.list__item {
  background-color: $color-white;
  width: 80%;
  border-radius: $border-radius-default;
  padding: 20px;
  @include box-shadow;
  margin-bottom: 15px;

  &:hover {
    transform: scale(101%);
  }
}

@media screen and (min-width: 641px) {
  /*LISTS*/
  .list__line {
    width: 80%;
    gap: 15px;
  }
}
