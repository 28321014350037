* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $color-white;
  color: $color-black;
  font-family: $font-default;
  line-height: 1.5;
  margin: 0;
  padding: 0;

  #app {
    position: static;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}
