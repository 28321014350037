/*FONTS*/
$font-default: "Open Sans", sans-serif;

/*COLORS*/
$color-dark-blue: #1768b1;
$color-light-blue: #3db8d8;
$color-white: white;
$color-black: #0a0a0a;
$color-light-light-blue: #eef8fe;
$color-light-grey: #e8e7e7;
$color-dark-grey: #515253;
$color-orange: #f59601;
$color-red: #ff0000;
$color-green: #008000;

$color-primary: $color-dark-blue;
$color-secondary: $color-light-blue;
$color-third: $color-white;
$color-fourth: $color-orange;

$color-text: $color-black;
$color-input: $color-light-light-blue;

/*OTHERS*/
$profil-tabBar-button-width: (100/2) * 1%;
$border-radius-default: 5px;
