/*STYLING OF BurgerMenuSlide, names can not be changed*/
.bm-item {
  display: inline-block;
  padding-bottom: 30px;
  color: $color-light-grey;
  transition: color 0.2s;
  background-color: $color-white;
}

.bm-burger-button {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 0px;
  top: 0px;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: $color-fourth;
}

.bm-menu {
  background: $color-white;
  padding: 0.5em 1em;
  font-size: 1.15em;
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  @include box-shadow;
}

/*Other menu styles*/
.menu {
  .menu__user {
    display: flex;

    .menu__user__section__title {
      margin-top: 10px;
    }

    .menu__user__name {
      display: none;
    }

    .menu__user__picture {
      width: 32px;
      border-radius: 50%;
    }
  }
}

@media screen and (min-width: 641px) {
  .bm-burger-button {
    width: 60px;
    height: 70px;
  }

  .menu {
    display: flex;
    margin-right: 10px;
    align-items: center;

    .menu__user {
      height: 100%;

      .menu__user__name {
        display: inline;
        padding-right: 5px;

        align-self: center;
      }
    }
  }
}
