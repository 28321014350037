.section--informations {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: $border-radius-default;

  max-width: 1000px;
}

.section--informations__picture {
  border-radius: 50%;
  width: 80%;
  margin: 30px auto;

  &.section--informations__picture--edit {
    cursor: pointer;

    &:hover {
      .profil-picture__image {
        opacity: 0.3;
      }
      .profil-picture__image--edit {
        width: 80%;
        height: 80%;
        opacity: 1;
      }
    }
  }

  .profil-picture__container {
    display: flex;
    justify-content: center;
    align-items: center;

    .profil-picture__image {
      border-radius: 50%;
      width: 100%;
    }

    .profil-picture__image--edit {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      text-align: center;
      align-content: center;
    }
  }
}

.section--informations__presentation {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  padding: 0 10px;
}

.section--informations__name {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  justify-content: center;

  .section--informations__name__serlian {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    h1 {
      font-size: 26px;
      color: $color-dark-blue;
    }

    h2 {
      font-weight: 700;
      text-align: center;
      font-size: 28px;
      color: $color-dark-blue;
      text-transform: none;
    }
  }
}

.section--informations__contact {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;

  .contact-line {
    display: flex;
    flex-direction: row;
    align-self: center;
    text-align: start;

    .contact-icons {
      height: 20px;
      margin-right: 10px;
    }
  }
}

.section--informations__profile-links {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;

  a {
    font-size: 30px;
    color: $color-dark-blue;
  }
}

.section--informations__current {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;

  p {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.section--informations__blog {
  margin-top: 30px;
}

@media screen and (min-width: 641px) {
  .section--informations {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    height: 350px;
  }

  .section--informations__picture {
    margin: 0 auto;

    .profil-picture__container {
      height: 100%;

      .profil-picture__image {
        border-radius: 20px;
        height: 100%;
        object-fit: cover;
        max-width: 350px;
      }
    }
  }

  .section--informations__presentation {
    justify-content: center;
  }

  .section--informations__name {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-left: 10%;

    h2 {
      text-align: start;
    }
  }

  .section--informations__profile-links {
    display: flex;
    align-self: flex-start;
    margin-left: 10%;
  }

  .section--informations__current {
    text-align: start;
    margin-left: 10%;
    width: 60%;
  }

  .section--informations__blog {
    margin-left: 10%;
    text-align: center;
    width: 80%;
  }
}

@media screen and (min-width: 645px) and (max-width: 1045px) {
  .section--informations__contact {
    margin-left: 10%;

    .contact-line {
      display: flex;
      flex-direction: row;
      align-self: flex-start;
    }
  }
  .section--informations__name {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: start;
    gap: 5px;
    .section--informations__name__serlian {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
}

@media screen and (min-width: 1045px) {
  .section--informations__contact {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    gap: 20px;
  }

  .section--informations__name {
    .section--informations__name__serlian {
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
  }
}
