.search__form {
  padding: 2px;
  display: flex;
  align-self: center;
  top: 60px;
  width: 100%;
  background-color: $color-light-grey;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 10px;

  .search__container {
    display: flex;
    border-radius: 20px;
    align-items: center;
    border-left: none;
    flex: 1;
    justify-content: space-between;

    .search__logo {
      margin: 0 5px;
      font-size: 18px;
    }

    .search__input {
      width: 90%;
      box-sizing: border-box;
      font-family: "Open Sans", sans-serif;
      color: $color-dark-grey;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 20px;
      border: none;
      outline: none;
      height: 30px;
      background-color: $color-light-grey;
    }

    .search__cross-button {
      border: none;
      font-size: 20px;
      color: $color-dark-grey;
      text-decoration: none;
      outline: none;
      background-color: transparent;
      visibility: hidden;

      &:hover {
        cursor: pointer;
      }

      &.show {
        visibility: visible;
        z-index: 2;
      }
    }
  }
}

@media screen and (min-width: 641px) {
  .search-form {
    background-color: $color-light-grey;
    margin: auto 0;
  }
}
