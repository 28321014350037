.table {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;

  th {
    margin-right: 5px;
    text-align: start;
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
  }

  tr {
    text-align: start;
    margin-top: 20px;

    td {
      flex: 1;
      padding: 10px;
      border-bottom: 1px solid #d9d9d9;
      margin-right: 5px;
      flex-direction: row;
    }

    .table__icon {
      width: auto;
      padding: 0;
      margin: 0;
      text-align: center;
    }

    .btn--round {
      display: unset;
    }
  }
}

.table__header {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  text-align: center;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
}

.table--missions {
  .table__cell {
    display: table-cell;
    width: auto;
  }
}

@media screen and (min-width: 40.06em) {
  .table {
    .form {
      width: 90% !important;
      margin: auto;
    }
  }
}
