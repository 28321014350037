.section--training {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 10px auto;

  .card--training {
    padding: 20px;

    .div__training {
      display: flex;
      gap: 15px;
      padding: 0 15px;
      margin-bottom: 15px;
    }
  }
}
