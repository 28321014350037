.container--profile {
  margin: 0 auto;
}

.section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.section__header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 15px 0;
  flex-wrap: wrap;

  .section__header__title {
    color: $color-dark-blue;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;

    .icon {
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 641px) {
  .container--profile {
    margin: 0 auto;
    width: 80%;
  }
}
