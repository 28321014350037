.img--contact {
  max-width: 75%;
  height: auto;
}

@media screen and (min-width: 641px) {
  .img--contact {
    max-width: 35%;
  }
}
