.block__skills-conferences {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  width: 100%;

  @media screen and (min-width: 641px) {
    flex-direction: row;
  }
}

.section--skills {
  display: flex;

  .card--skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    grid-column: 2;
    .div__skill {
      display: flex;
      letter-spacing: 1px;
      margin-bottom: 5px;
      font-size: 14px;
      align-items: center;
      gap: 5px;
      margin-right: 8px;

      .btn {
        background-color: $color-dark-blue;
      }

      .skill {
        margin-left: 5px;
      }

      .div-drag {
        border: dashed 2px $color-light-blue;
        padding: 2px 6px;
        border-radius: 5px;
      }
    }
    .div__skills-to-validate {
      color: #aaa;
      font-style: italic;
      padding-top: 5px;
      border-top: 1px solid $color-dark-grey;

      .div__skills-to-validate__title {
        margin-bottom: 5px;
      }

      .div__skills-to-validate__content {
        margin-left: 10px;

        .skill:last-child {
          .divider {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.section--conferences {
  .card--conferences {
    height: 100%;
    padding: 20px;
    align-items: flex-end;
  }

  .div__conference {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    width: 100%;
    align-items: flex-start;
    gap: 5px;

    .conference_year {
      background-color: $color-light-light-blue;
      padding: 10px 15px;
      border-radius: 5px;
      h2 {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .div__conference__description {
      display: flex;
      flex-direction: column;
      padding-left: 10px;

      .conference__name {
        font-weight: 550;
        color: $color-dark-blue;
        margin: 0;
        font-size: 16px;
        letter-spacing: 1px;
      }

      .conference__link {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 10px;

        a {
          &:link {
            font-size: 14px;
            color: $color-white;
            padding: 2px 15px;
            border-radius: $border-radius-default;
            background-color: $color-dark-blue;
          }

          &:visited {
            color: $color-light-blue;
          }

          &:hover {
            transform: scale(103%);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 641px) {
  .section--skills {
    width: 50%;
    margin: 0;
    margin-right: 10px;

    .card--skills {
      .div-skill {
        padding: 5px 15px;
        font-size: 14px;
      }
    }
  }
  .section--conferences {
    width: 50%;
  }
}
