/*BUTTONS*/

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: $color-white;
  border: none;
  border-radius: $border-radius-default;
  cursor: pointer;
  padding: 10px 10px;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  margin-bottom: 10px;
  // transition: all 0.2s linear, opacity 0.1s linear;

  p {
    font-size: 10px;
  }
  &:hover {
    background-color: $color-secondary;
    cursor: pointer;
    color: $color-white;
  }

  &:active {
    opacity: 0.7;
  }
}

.btn--centered {
  align-self: center;
}

.btn--round {
  color: $color-black;
  border: 1px solid $color-secondary;
  padding: 7px;
  border-radius: 50%;
  outline: none;
  margin: 0 5px;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: $color-light-blue;
    color: $color-white;
  }

  &:active {
    opacity: 0.4;
  }

  .edit {
    color: $color-white;
  }
}
.btn--blue {
  background-color: $color-primary;
}

.btn--border-blue {
  border: 2px solid $color-secondary;
  height: 30px;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 320px;
  overflow: hidden;
  color: $color-primary;

  &:hover {
    transform: scale(103%);
    background-color: transparent;
    color: $color-primary;
  }
}

.btn--selected {
  border: 2px solid none;
  border: none;
  background-color: $color-primary;
  color: $color-white;

  &:hover {
    transform: scale(103%);
    background-color: $color-primary;
    color: $color-white;
    // background-color: transparent;
  }
}

.btn--light-blue {
  border: 2px solid transparent;
  background-color: $color-light-blue;
  height: 30px;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 320px;
  overflow: hidden;
  color: $color-white;

  &:hover {
    transform: scale(103%);
    background-color: transparent;
    color: $color-secondary;
    border-color: $color-secondary;
  }
}

.btn--light-blue-selected {
  border: 2px solid transparent;
  background-color: $color-primary;
  color: $color-white;

  &:hover {
    transform: scale(103%);
    background-color: transparent;
    color: $color-primary;
    border-color: $color-primary;
    // background-color: transparent;
  }
}

.btn--down {
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  padding: 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  cursor: pointer;
  font-size: 20px;

  svg {
    color: $color-primary;
  }
}

.btn--transparent {
  background-color: transparent;
  border: none;
}

.cross-button {
  border: none;
  font-size: 20px;
  color: $color-dark-grey;
  text-decoration: none;
  outline: none;
  visibility: hidden;
  position: absolute;
  right: 1px;
  bottom: 10px;
  padding-left: 0;
  background-color: $color-light-light-blue;

  &:hover {
    cursor: pointer;
  }

  &.reset-edit {
    right: 11px;
    padding-right: 5px !important;
  }

  &.show {
    visibility: visible;
  }
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    background-color: $color-primary;
  }
}

.btn--loader {
  border: 3px solid $color-light-light-blue;
  border-radius: 50%;
  border-top: 3px solid $color-primary;
  width: 11px;
  height: 11px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.btn__text {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
