/*CONTAINERS*/
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 5px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.container--column {
  flex-direction: column;
  align-items: center;
}

.container--centered {
  justify-content: center;
  text-align: center;
  gap: 5px;
  padding: 0px;
}

.container--page-error {
  margin-top: 50vh;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

/*CARDS*/
.card {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-default;
  // @include box-shadow;
}

.card__insert {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;

  h4 {
    line-height: 1;
  }
}

.card__text {
  display: none;
}

.card--serlian {
  color: $color-black;
  width: 100px;
  height: 140px;
  position: relative;
  box-sizing: border-box;
  align-items: center;

  @include box-shadow;
  background-color: $color-white;

  &:hover {
    transform: scale(103%);
    cursor: pointer;
  }
  .img {
    height: 70%;
    width: 70%;
    margin-top: 7%;
    object-fit: cover;
    align-self: center;
  }
}

@media screen and (min-width: 641px) {
  .container {
    align-items: center;
    gap: 15px;
    padding: 15px 10px;
  }

  .container--centered {
    justify-content: center;
    text-align: center;
    gap: 8px;
    padding: 0px 5px;
  }

  .container--column {
    flex-direction: column;
  }

  .card--serlian {
    width: 200px;
    height: 280px;
    margin-top: 30px;

    .img {
      height: 80%;
      width: 80%;
    }
  }

  .card__text {
    display: inline;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    color: $color-dark-grey;
  }
}
